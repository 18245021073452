<template>
  <div>
    <CRow>      
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover          
          striped
          border
          small
          fixed
          caption="Lista de Clientes"
          icon="fa fa-users"
          btn_name="cliente"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
        />
      </CCol>
    </CRow>

    <!-- modal Cliente -->
    <cModalClient
      :title="modal.title"
      :boo_modal="modal.modal_form"
      :item="modal.item"
      @close_modal="close_modal"
      @mtd_action="mtd_action"
    >
    </cModalClient>

    <!-- modal delete -->
    <cModalDelete
      title="Borrar Cliente"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>

  </div>
</template>

<script>
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cModalClient from "./modal_client.vue";
import { mapActions } from "vuex";
import { bus } from '../../main'

export default {
  components: { CTableWrapper, cModalDelete, cModalClient },
  data() {
    return {
      prefix: "client",
      fields:[
        "Id",
        "Nombre", 
        "Apellidos", 
        'DNI',
        "Telefono", 
        "Email", 
        "Direccion" // resource
      ],
      data:[],
      clients: [],
      modal: {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      },
      client: {
        id: "",
        name: "",
        last_name: "",
        dni:"",
        phone: "",
        email: "",
        address: "",
        description: "",
        status: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    }
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get","post"]),
    mtd_getdata: function(){
      this.get({
        url: this.$store.getters.get__url + "/client",
        token: this.$store.getters.get__token
      })
        .then((response) => {       
          this.data = response.data;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Nuevo Cliente")
        : (this.modal.title = "Editar Cliente");
      action=="store"
       ? (this.modal.item = [])
        : this.client = this.client;
    },
    mtd_action: function () {

      this.client= {
        id: this.modal.item.id,
        name: this.modal.item.name,
        last_name: this.modal.item.last_name,
        dni:this.modal.item.dni,
        phone: this.modal.item.phone,
        email: this.modal.item.email,
        address: this.modal.item.address,
        description: this.modal.item.description,
        status: this.modal.item.status,
      };

      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.client,
      })
        .then((response) => {
          let color = "success";
          let message = "";
          if (this.modal.action == "store") {
            if(response[0].Id == 1){
              this.mtd_getdata();
            }else{
              this.data.push(response[0]);
            }
            message = "REGISTRADO CORRECTAMENTE";
          } else {
            this.data.forEach((element) => {
              if (element.Id == response[0].Id) {
                element.Nombre = response[0].Nombre;
                element.Apellidos = response[0].Apellidos;
                element.DNI=response[0].DNI;
                element.Telefono = response[0].Telefono;
                element.Email = response[0].Email;
                element.Descripcion = response[0].Descripcion;
              }
              message = "EDITADO CORRECTAMENTE";
            });
          }
          bus.$emit('alert', { 
            color:color,
            message:message
          });

          this.close_modal();
        })
        .catch((errors) => {
           bus.$emit('alert', { 
            color:'danger',
            message:errors
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
          token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.client = response;
          this.modal.item = response;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {});
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    close_modal: function () {
      this.modal.modal_form = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          let temp = [];
          this.data.forEach((element) => {
            if (element.Id != response) {
              temp.push(element);
            }
          });
          this.data = temp;
          this.modal_delete= {
            boo: false,
            item: [],
          };
           bus.$emit('alert', { 
            color:'success',
            message:'ELIMINADO CORRECTAMENTE'
          });
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search{
  color: #fff;
      background-color: #2819ae;
    border-color: #2517a3;
}
</style>